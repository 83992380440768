import(/* webpackMode: "eager" */ "/vercel/path0/app/node_modules/@mantine/core/esm/core/MantineProvider/ColorSchemeScript/ColorSchemeScript.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/app/node_modules/mantine-datatable/dist/styles.layer.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/app/[locale]/style.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/node_modules/@mantine/carousel/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/node_modules/@mantine/core/styles.layer.css");
import(/* webpackMode: "eager" */ "/vercel/path0/app/node_modules/next/font/google/target.css?{\"path\":\"src/theme/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\",\"vietnamese\"]}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/app/node_modules/next/font/google/target.css?{\"path\":\"src/theme/fonts.ts\",\"import\":\"Be_Vietnam_Pro\",\"arguments\":[{\"subsets\":[\"latin\",\"vietnamese\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"bevn\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/components/Modal/ConnectModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/components/Modal/SlippageModal.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/i18n/LinguiProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/providers/ClusterProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/providers/DappProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/providers/QueryProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/stores/application/ApplicationProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/stores/modal/ModalProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/app/src/theme/ThemeProvider.tsx")