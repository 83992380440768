"use client";

import { Button, Overlay, createTheme, rem } from "@mantine/core";

export const theme = createTheme({
	primaryColor: "purple",
	breakpoints: {
		xxs: "20em",
		xs: "30em",
		sm: "48em",
		md: "64em",
		lg: "74em",
		xl: "120em", // 90rem
	},
	colors: {
		dark: [
			"#F9FAFB",
			"#F3F4F6",
			"#E5E7EB",
			"#D1D5DB",
			"#111827",
			"#6B7280",
			"#171717",
			"#000000",
			"#1F2937",
			"#111827",
		],
		gray: [
			"#FFFFFF",
			"#F2F2F2",
			"#E5E5E5",
			"#D9D9D9",
			"#CCCCCC",
			"#BFBFBF",
			"#B3B3B3",
			"#A6A6A6",
			"#999999",
			"#8C8C8C",
			"#808080",
			"#737373",
			"#666666",
			"#595959",
			"#4D4D4D",
			"#404040",
			"#333333",
			"#262626",
			"#1A1A1A",
			"#0D0D0D",
			"#000000",
			"#D2D2D2",
			"#828282",
		],
		purple: [
			"#ECEBFF",
			"#D6CFFC",
			"#C3B8F9",
			"#FFFFFF",
			"#9F8FEF",
			"#8F7DE8",
			"#5036D1",
			"#705CD6",
			"#624DCB",
			"#5638EB",
			"#5036D1",
			"#3D29A3",
			"#321F93",
			"#291782",
			"#201070",
			"#180A5C",
			"#110647",
			"#0A0330",
			"#040116",
			"#B1A3F5",
			"#180A5C",
		],
		success: [
			"#ECFDF5",
			"#D1FAE5",
			"#A7F3D0",
			"#6EE7B7",
			"#34D399",
			"#10B981",
			"#059669",
			"#99F6EE",
			"#14B8A9",
			"#0F766D",
			"#00B8A1",
		],
		error: [
			"#FEF2F2",
			"#FEE2E2",
			"#FECACA",
			"#FCA5A5",
			"#F87171",
			"#EF4444",
			"#DC2626",
			"#F6ABAB",
			"#E44F4F",
			"#991B1B",
		],
	},
	components: {
		Overlay: Overlay.extend({}),
		Button: Button.extend({
			vars: (_, props) => {
				if (props.size === "xxs") {
					return {
						root: {
							"--button-height": rem(24),
							"--button-padding-x": rem(10),
							"--button-fz": rem(10),
						},
					};
				}
				return { root: {} };
			},
		}),
	},
});
