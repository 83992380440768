'use client';

import type { Messages } from '@lingui/core';
import { setupI18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import type { PropsWithChildren } from 'react';
import type { Locale } from './i18n-config';
type LinguiProviderProps = PropsWithChildren<{
  locale: Locale;
  messages: Messages;
}>;
export const LinguiProvider: React.FC<LinguiProviderProps> = ({
  children,
  locale,
  messages
}) => {
  return <I18nProvider i18n={setupI18n({
    locale,
    messages: {
      [locale]: messages
    }
  })}>
      {children}
    </I18nProvider>;
};